import { useState } from "react";
import questionair from "../data/questionair.json"
import { redirect } from "react-router-dom";
import style from "../styles/Questionair.css";

import { useTranslation } from "react-i18next";

export default function Questionair({onClose}) {

    const { t } = useTranslation();

    const [formData, setFormData] = useState(questionair);
    const [message, setMessage] = useState("");

    // Funktion zum Aktualisieren der Eingabewerte
    const handleChange = (index, event) => {
      const newFormData = [...formData];

      // Checkbox speziell behandeln
      if (newFormData[index].inputType === "checkbox") {
        newFormData[index].input = event.target.checked;
      } else {
        newFormData[index].input = event.target.value;
      }

      setFormData(newFormData);
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Verhindert das Neuladen der Seite
    
        try{
            const response = await fetch("https://competefit.munich-software-studios.de/questionair", {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
        })

        const data = await response.json();
        
        if (data.success) {
            console.log('Success:', data);
            redirect('/overview')
          } else {
            setMessage(t('MESSAGE.ERROR.REGISTRATION'));
          }
        } catch (error) {
          setMessage("Ein Fehler ist aufgetreten.");
        }
      };

    return(
        <>
          <div className="modal-overlay" onClick={onClose}>
            <div className="questionair-content">
              <h1 className="questionair-header">{t('QUESTIONAIR.HEADER')}</h1>
              <hr className="questionair-line"/>
              <div className="q-container">
                <p className="questionair-subtext">{t('QUESTIONAIR.SUBTEXT')}</p>
                <form onSubmit={handleSubmit}>
                  <div className="scrollable">
                  {formData.map((question, index) => (
                      <div key={index} className="form-content">
                        <label className="questionair-label">
                          <strong>{t(question.question)}</strong>
                        </label>
                        <div className="question-div">
                        {question.inputType === "input" && (
                          <input
                            className="questionair-input"
                            type="text"
                            placeholder={t(question.input)}
                            onChange={(event) => handleChange(index, event)}
                          />
                        )}
                        </div>
                        {question.inputType === "checkbox" && (
                          <div className="question-div">
                          {question.options.map((option, optionIndex) => (
                              <div key={optionIndex}>
                                <label className="questionair-checkbox">
                                  <input type="checkbox" onChange={(event) => handleChange(index, optionIndex, event)} checked={option.checked} />
                                  <span className="checkmark"></span>
                                  <span className="label">{t(option.label)}</span>
                                </label>
                              </div>
                          ))}
                          </div>
                        )}
                        {question.inputType === "slider" && (
                          <div className="question-div">
                          <input
                            className="questionair-slider"
                            type="range"
                            min={question.min}
                            max={question.max}
                            value={question.input}
                            onChange={(event) => handleChange(index, event)}
                          />
                          <div className="slider-label">
                            {Array.from({ length: question.max - question.min +1}, (_, i) => (
                              <span key={i}>{i + question.min}</span>
                            ))}
                          </div>
                          </div>
                        )}
                        {question.inputType === "datepicker" && (
                          <div className="question-div">
                          <input
                            className="questionair-input"
                            type="date"
                            value={question.input}
                            onChange={(event) => handleChange(index, event)}
                          />
                          </div>
                        )}
                      </div>
                    ))}
                    <hr/>
                    <button className="btn-questionair btn-primary" type="submit">{t('BUTTON.GENERATEPLAN')}</button>
                  </div>
                </form>
                {message && <p className='errorMeesage'>{message}</p>}
              </div>
            </div>
          </div>
            

        </>
    )

}