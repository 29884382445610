import { useTranslation } from "react-i18next";
import MonthCard from "../components/MonthCard";
import moths from "../data/monthCards.json";
import { useState } from "react";
import { redirect } from "react-router-dom";

export default function OverviewPage() {

    const { t } = useTranslation();

    const [currentIndex, setCurrentIndex] = useState(0);

    const totalCards = moths.length;

    function handlePrev(){
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? totalCards - 1 : prevIndex - 1
      );
    };

    function handleNext(){
      setCurrentIndex((prevIndex) =>
        prevIndex === totalCards - 1 ? 0 : prevIndex + 1
      );
    };

    function handleOpenMonth(month) {
        console.log(month);
    }

    function gotoCurrentWorkspace() {
        redirect('/welcome');
        console.log("go to current Workspace");
    }

    return(
        <div className="overview-container">
            <div className="overview-header">
                <h1>WELCOME BACK, USERNAME</h1>
                <hr className="overview-line"/>
                <p className="overview-subtext">{t('OVERVIEW.SUBTEXT')}</p>
                <button className="btn-standard btn-primary btn-overview" onClick={gotoCurrentWorkspace}>{t('BUTTON.CONTINUE')}</button>
            </div>
            <div className="monthCard-content">
                {moths.length > 3 && (
                  <button className="carousel-button prev" onClick={handlePrev}>
                    {"<"}
                  </button>
                )}

                {moths.slice(currentIndex, currentIndex + 3).map((month, index) => (
                    <MonthCard key={index} month={month.month} subtext={month.subtext} isDone={month.isDone} inProgress={month.inprogress} openMonth={handleOpenMonth}/>
                ))}

                {moths.length > 3 && (
                  <button className="carousel-button next" onClick={handleNext}>
                    {">"}
                  </button>
                )}
            </div>
        </div>
    )
}