import { faParachuteBox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outlet, Link } from 'react-router-dom';

export default function MonthOverview() {

    return(
        <>
        <FontAwesomeIcon icon={faParachuteBox}/>
        <Outlet/>
        </>
    )
}