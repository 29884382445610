import { Link } from "react-router-dom"
import { useState } from "react";
import Questionair from "../components/Questionair";
import styles from "../styles/Startpage.css";

import { useTranslation } from "react-i18next";

export default function StartPage() {

    const { t } = useTranslation();

    const [isQuestionairOpen, setIsQuestionairOpen] = useState(false);

    const handleOpenQuestionair = () => {
        setIsQuestionairOpen(true);
    }

    const handleCloseQuestionair = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
            setIsQuestionairOpen(false);
        }
    };

    return(
        <div className="start-container">
            <h1>WELCOME USERNAME</h1>
            <hr className="start-line"/>
            <p className="start-welcometext">{t('STARTPAGE.WELCOMETEXT')}</p>
            <button className="btn-start btn-primary" onClick={handleOpenQuestionair}>{t('BUTTON.STARTQUESTIONAIR')}</button>
            <p><Link to="/">{t('BUTTON.BACK')}</Link></p>
            {isQuestionairOpen ?
            <Questionair onClose={handleCloseQuestionair}/>
            :
            <></>}
        </div>
    )
}