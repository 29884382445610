import { Form } from 'react-router-dom';
import { useTranslation } from "react-i18next";


export default function LoginField({openSignUp}) {

  const { t } = useTranslation();

    return (
        <>
          <Form method="post" className="form">
            <p className='headline-standard'>{t('LOGIN.HEADER')}</p>
              <input className="input-standard" id="username" type="username" name="username" placeholder={t('PLACEHOLDER.USERNAME')} required />
              <input className="input-standard" id="password" type="password" name="password" placeholder={t('PLACEHOLDER.PASSWORD')} required />
            <div className="form-action">
              <button className='btn-standard btn-primary'>{t('BUTTON.SIGNIN')}</button>
              <button className='btn-standard btn-secondary' type="button" onClick={openSignUp}>
              {t('BUTTON.SIGNUP')}
              </button>
            </div>
          </Form>
        </>
    );
}