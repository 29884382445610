import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ContactForm({onClose, ...props}) {

    const { t } = useTranslation();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [feedback, setFeedback] = useState("");
    const [message, setMessage] = useState("");
    const [selectedOption, setSelectedOption] = useState(''); // State für die ausgewählte Option

    function changeSubject(event){
        setSelectedOption(event.target.value);
    };

    return(
        <div className="modal-overlay" onClick={onClose}>
            <div className="signup-content">

            <form>
              <p className='headline-standard'>{t('CONTACT.HEADER')}</p>
              <hr className='signup-line'/>
              <p className='signup-subtext'>{t('CONTACT.SUBTEXT')}</p>
                <div className="contact-form-content">
                <div>
                  <input
                    className="input-signup"
                    type="text"
                    placeholder={t('PLACEHOLDER.USERNAME')}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <input
                    className="input-signup"
                    type="email"
                    placeholder={t('PLACEHOLDER.EMAIL')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="contact-label">{t('CONTACT.SUBJECT.LABEL')}:</label>
                  <select onChange={changeSubject} className="contact-selection">
                      <option value={t('CONTACT.SUBJECT.COACHING')}>{t('CONTACT.SUBJECT.COACHING')}</option>
                      <option value={t('CONTACT.SUBJECT.FEEDBACK')}>{t('CONTACT.SUBJECT.FEEDBACK')}</option>
                  </select>
                </div>
                <div>
                  <textarea
                    className="input-signup"
                    type="text"
                    placeholder={t('PLACEHOLDER.MESSAGE')}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    required
                  />
                </div>
                <button className='btn-signup' type="submit">{t('BUTTON.SENTMESSAGE')}</button>
              </div>
            </form>
            {message && <p className='errorMeesage'>{message}</p>}
          </div>
        </div>
    )
}