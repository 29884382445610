import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DropdownMenu({items, handleSelection, ...props}) { 

    const [selectedOption, setSelectedOption] = useState(''); // State für die ausgewählte Option

    function handleSelectChange(event){
        setSelectedOption(event.target.value);
        handleSelection(event.target.value);
    };

    return (
        <div className="custom-select-container">
            <div className="select-label">
              {props.title}
              <FontAwesomeIcon icon={props.icon} className="select-icon" size="xs"/>
            </div>
            <select className="custom-select" onChange={handleSelectChange}>
            {items.map((item, index) => (
                <option key={index} value={item}>{item}</option>
            ))}
          </select>
        </div>
    )
}